// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-pink-button-container {
  display: flex;
  position: relative;
}
.primary-pink-button-button {
  fill: #ffffffff;
  color: #ffffffff;
  outline: none;
  box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
  transition: all .15s ease-in;
  padding-top: var(--dl-space-space-triplequarterunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  border-radius: 1.875rem;
  padding-right: var(--dl-space-space-unitandahalfunit);
  padding-bottom: var(--dl-space-space-triplequarterunit);
  background-color: #776d65fa;
}
.primary-pink-button-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}
`, "",{"version":3,"sources":["webpack://./src/components/primary-pink-button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,2EAA2E;EAC3E,4BAA4B;EAC5B,oDAAoD;EACpD,iBAAiB;EACjB,oDAAoD;EACpD,uBAAuB;EACvB,qDAAqD;EACrD,uDAAuD;EACvD,2BAA2B;AAC7B;AACA;EACE,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".primary-pink-button-container {\n  display: flex;\n  position: relative;\n}\n.primary-pink-button-button {\n  fill: #ffffffff;\n  color: #ffffffff;\n  outline: none;\n  box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);\n  transition: all .15s ease-in;\n  padding-top: var(--dl-space-space-triplequarterunit);\n  border-width: 0px;\n  padding-left: var(--dl-space-space-unitandahalfunit);\n  border-radius: 1.875rem;\n  padding-right: var(--dl-space-space-unitandahalfunit);\n  padding-bottom: var(--dl-space-space-triplequarterunit);\n  background-color: #776d65fa;\n}\n.primary-pink-button-button:hover {\n  cursor: pointer;\n  transform: scale(1.02);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
